<template>
  <v-row justify="center">
    <v-dialog v-model="modal" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Додавання телефону</span>
        </v-card-title>

        <v-icon class="close_cart pa-2" @click="$emit('close')"
          >mdi-close</v-icon
        >

        <v-form ref="form" v-model="valid" @submit.prevent="addPhone">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :loading="loading"
                  dense
                  outlined
                  autofocus
                  label="Телефон*"
                  v-model="phone"
                  :value="phone"
                  :rules="rules.concat(allowPhone)"
                  required
                  v-mask="'+38(###)-###-##-##'"
                  placeholder="+38(###)-###-##-##"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="dark" text type="button" @click="$emit('close')">
              Скасувати
            </v-btn>
            <v-btn
              v-if="valid && isAllowPhone"
              color="success"
              text
              type="submit"
            >
              Додати
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalAddPhone',
  props: {
    show: {
      default: false
    },
    currentPhones: {
      type: Array
    }
  },
  data: () => ({
    phone: '',
    rules: [
      (v) => !!v || "Це поле обов'язкове",
      (v) =>
        (v && v.length === 18) ||
        'Перевірте правільність номеру, кол-во символів повино бути 12'
    ],
    allowPhone: [],
    valid: true,
    loading: false,
    isAllowPhone: false,
    modal: false
  }),
  watch: {
    phone (val) {
      if (val.length === 18) {
        this.checkPhone(val)
      }
    },
    show (val) {
      this.modal = val
    }
  },
  methods: {
    ...mapActions('user', ['checkUserPhone']),
    async addPhone () {
      if (this.valid) {
        this.$emit('add', this.phone.replace(/\D/g, '').slice(2))
        this.phone = ''
        this.$emit('close')
      }
    },
    async checkPhone (phone) {
      this.loading = true
      this.isAllowPhone = false
      try {
        await this.checkUserPhone(phone.replace(/\D/g, '').slice(2))

        if (this.currentPhones.length) {
          let has = false
          this.isAllowPhone = true
          this.currentPhones.forEach((el) => {
            if (el.number === phone) {
              has = true
              this.isAllowPhone = false
            }
          })
          this.allowPhone = has ? ['Цей номер не доступен'] : []
        } else {
          this.allowPhone = []
          this.isAllowPhone = true
        }
      } catch (e) {
        this.allowPhone = ['Цей номер не доступен']
      }
      this.loading = false
      this.$refs.form.validate()
    }
  }
}
</script>
